import XHR from '@/request/http'
const $h = new XHR()
// url
const url = {
    lists:'/admin/v1/subjects/page',
    update:'/admin/v1/subjects/update',
    create:'/admin/v1/subjects/create',
    del:'/admin/v1/subjects/del',
    tree:'/admin/v1/subjects/tree',//tree
    get_subject:'/admin/v1/subjects/get', //获取
    subjects:'/admin/v1/subjects/list',//用户学科
}


export async function lists(data)
{
    return $h.request(url.lists,data,'GET')
}

export async function create(data)
{
    return $h.request(url.create,data,'POST')
}

export async function update(data)
{
    return $h.request(url.update,data,'POST')
}

export async function del(data)
{
    return $h.request(url.del,data,'POST')
}

export async function get_subject(data)
{
    return $h.request(url.get_subject,data,'GET')
}

export async function tree(data)
{
    return $h.request(url.tree,data,'GET')
}

export async function subjects(data)
{
    return $h.request(url.subjects,data,'GET')
}


export async function expert_dict(data)
{
    return $h.request(url.expert_dict,data,'GET')
}

export async function teacher_dict(data)
{
    return $h.request(url.teacher_dict,data,'GET')
}

export async function type_lists(data)
{
    return $h.request(url.type_lists,data,'GET')
}

export async function type_create(data)
{
    return $h.request(url.type_create,data,'POST')
}

export async function type_update(data)
{
    return $h.request(url.type_update,data,'POST')
}


export async function type_del(data)
{
    return $h.request(url.type_del,data,'POST')
}

