import XHR from '@/request/http'
const $h = new XHR()
// url
const url = {
    login:'/admin/v1/auth/login',
    logout:'',
    upload:'/admin/v1/file/upload',
    video_upload:'/admin/v1/file/video/upload',
}

//登录
export async function login(data)
{
    return await $h.request(url.login,data,'POST')
}

//退出登录
export async function logout(data)
{
    return $h.request(url.logout,data,'POST')
}

export async function getFileUrl()
{
    // return ($h.config.baseURL+url.upload);
    return url.upload;
}

export function getVideoUrl()
{
    return url.video_upload;
}
//注册


