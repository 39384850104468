<template>
    <el-drawer
        title="添加"
        :visible.sync="drawer"
        :modal="true"
        :wrapperClosable="false"

        @close="closeDrawer"
        :modal-append-to-body="false"
        :direction="direction">

        <!-- title -->
        <div class="drawer_form">
            
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="110px" class="demo-ruleForm">
                
                <div class="form-body">
                    <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
                        <el-row>
                            <el-col  :xs="24" :md="12">
                                <el-form-item label="评课标题" prop="">
                                    <el-input type="text" v-model="form.title" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col  :xs="24" :md="12">
                                <el-form-item label="班级" prop="">
                                    <el-input type="text" v-model="form.className" autocomplete="off"></el-input>
                                </el-form-item>    
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :xs="24" :md="12">
                                <el-form-item label="课程名称" prop="">
                                    <el-input type="text" v-model="form.courseName" autocomplete="off"></el-input>
                                </el-form-item>    
                            </el-col>
                            <el-col  :xs="24" :md="12">
                                <el-form-item label="评课模板" prop="">
                                    <el-select
                                    filterable
                                    reserve-keyword
                                    remote
                                    clearable
                                    :remote-method="remoteMethodTemplate"
                                    placeholder="模板模糊查询"
                                    v-model="form.templateId"
                                    @change="changeTemplate"
                                    class="w-full">
                                        <el-option
                                        v-for="item in template.lists"
                                        :key="item.id"
                                        :label="item.templateName"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>        
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col  :xs="24" :md="12">
                                <el-form-item label="评课方式" prop="">
                                    <el-select @change="changeType" v-model="form.type" placeholder="请选择" style="width: 100%;">
                                        <el-option
                                        v-for="item in $store.state.dict.class_eva_type"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-form-item label="上传视频文件" prop="" v-if="form.type==1">
                            <!-- <el-upload
                                class="upload-demo" 
                                :on-change="aliplayerUpload"
                                action=""
                                :auto-upload="false"
                                :limit="1">
                                <el-button size="small" type="primary">点击上传视频文件</el-button>
                            </el-upload> -->
                            <!-- <el-upload
                                action=""
                                :on-change="aliplayerUpload"
                                :limit="1"
                                :auto-upload="false">
                                <el-button style="margin-left: 10px;" size="small" type="success" @click="startUpload">上传Aliyun</el-button>
                                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                                <div slot="tip" class="el-upload__tip">{{ stsProgress }}</div>
                            </el-upload> -->

                            <div style="display: flex;flex-direction: column;">
                                <input type="file" @change="aliplayerUpload">
                                <el-button style="width: 120px;margin: 10px 0;" type="primary" size="mini" @click="startUpload">上传</el-button>
                                <div v-if="stsProgress">上传{{ stsProgress }}%</div>
                            </div>
                        </el-form-item>
                        
                        <el-form-item label="直播流地址" prop="" v-if="form.type==2">
                            <el-input type="text" v-model="form.liveStreamUrl" autocomplete="off"></el-input>
                        </el-form-item>  
                        <el-form-item label="视频地址" prop="" v-if="form.type==3">
                            <el-input type="text" v-model="form.videoUrl" autocomplete="off"></el-input>
                            <el-button type="primary" size="mini" @click="outUpload">上传</el-button>
                        </el-form-item> 
                        <el-row v-if="form.type==2">
                            <el-col  :xs="24" :md="12">
                                <el-form-item label="开始时间" prop="">
                                    <el-date-picker
                                        style="width: 100%;"
                                        v-model="form.liveStartTime"
                                        type="datetime"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择日期时间"
                                        >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col  :xs="24" :md="12">
                                <el-form-item label="结束时间" prop="">
                                    <el-date-picker
                                        style="width: 100%;"
                                        v-model="form.liveEndTime"
                                        type="datetime"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择日期时间"
                                        >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        
                        <el-row>
                            <el-col  :xs="24" :md="12">
                                <el-form-item label="学科" prop="">
                                    <el-cascader
                                        v-model="form.subjectId"
                                        :options="subject.tree"
                                        @change="handleCascader"
                                        :show-all-levels="false"
                                        style="width: 100%;"
                                        :props="{ checkStrictly: true,label:'name',value:'id',emitPath:false }"
                                        clearable
                                    ></el-cascader>
                                </el-form-item>
                            </el-col>
                            <el-col  :xs="24" :md="12">
                                <el-form-item label="教学老师" prop="" v-if="form.subjectId">
                                    <el-select 
                                        filterable
                                        remote
                                        reserve-keyword
                                        clearable
                                        placeholder="教学老师模糊查询"
                                        :remote-method="remoteMethod"
                                        v-model="form.teacherId"
                                        class="w-full">
                                            <el-option
                                            v-for="item in teacher.lists"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                            </el-option>
                                        </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-form-item label="评课附加提示词" prop="">
                            <el-input type="textarea" v-model="form.description" autocomplete="off"></el-input>
                        </el-form-item>
                        
                        
                        <el-form-item label="评课教师" prop="">
                            <el-tree
                                ref="tree"
                                :data="data"
                                show-checkbox
                                node-key="id"
                                :default-expanded-keys="[]"
                                :props="defaultProps">
                            </el-tree>

                        </el-form-item>

                    </overlay-scrollbars>
                </div>

                <div class="form-foot d-flex a-center j-center">
                    <el-button @click="onSubmit" type="primary" class="" style="width:200px">提交</el-button>
                </div>
            </el-form>
        </div>

    </el-drawer>
    
</template>
<script>
import {getVideoUrl} from '@/request/api/login.js'
import {lists as teacher_list} from '@/request/api/teacher.js'
import {lists as template_list} from '@/request/api/module.js'
import {create,lesson_out_upload} from '@/request/api/lession.js'
import {tree} from '@/request/api/teacher.js'
import {subjects} from '@/request/api/subject.js'
import {getStstoken} from '@/request/api/user.js'
export default {
    data(){
        return {
            videoUrl:getVideoUrl(),
            drawer: true, //表单
            direction: 'rtl',//抽屉方向
            loading:false,
            stsProgress:0,//上传进度
            statusText:'',
            form:{
                title:'',
                className:'',//班级
                courseName:'',//课程
                subjectId:'',//学科id
                teacherId:'',//教师id
                templateId:'',//模板id
                liveStreamUrl:'',//直播地址
                videoUrl:'',//视频地址
                type:1,//评课方式
                reviewTeachers:'',//评课老师，逗号分割
                liveStartTime:'',//直播开始时间和结束时间
                liveEndTime:'',//直播开始时间和结束时间
                description:'', //教学主题、学段和课型
            },
            
            rules:{
                nickname:[
                    { required: true, message: '请输入设备名称', trigger: 'blur' },
                ],
            },
            teacher:{
                lists:[],//列表
                query:{keyword:'',page:1,size:100}
            },
            template:{
                lists:[],//模板
                query:{name:'',page:1,size:100}
            },
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            data: [],
            subject:{
                tree:null
            }
        }
    },
    created(){
        this.init()
    },
    methods:{
        init(){
            this.get_teacher()
            this.get_template()
            this.getTree()
            this.get_subject()
        },

        //选择文件
        aliplayerUpload(e){
            this.file = e.target.files[0]
            getStstoken().then(res=>{
                let self = this
                let info = res.data.credentials
                let workflowId = res.data.workflowId
                let region = res.data.region || 'cn-beijing'
                let accessKeyId = info.accessKeyId  
                let accessKeySecret = info.accessKeySecret
                let securityToken = info.securityToken
                let uploader = new window.AliyunUpload.Vod({
                    localCheckpoint: true,
                    region:region,
                    onUploadstarted:function(uploadInfo){
                        uploader.setSTSToken(uploadInfo, accessKeyId, accessKeySecret, securityToken)
                    },
                    onUploadProgress:function(uploadInfo, totalSize, progress){
                        let progressPercent = Math.ceil(progress * 100)
                        self.stsProgress = progressPercent
                    },
                    onUploadSucceed: function (uploadInfo) {
                        self.form.videoUrl = uploadInfo.videoId
                        self.statusText = '文件上传成功!'   
                    },
                })
                
                //
                var paramData = `{"Vod":{"Title":"${this.file.name}","WorkflowId":"${workflowId}"}}`
                // var paramData = `{"Vod":{}}`
                uploader.addFile(this.file, null, null, null, paramData)
                this.aliUpload = uploader
            })
            
        },

        //远程地址上传
        outUpload(){
            lesson_out_upload(this.form.videoUrl).then(res=>{
                if(res.status !== 200) return this.$message.error('HTTP Error :'+res.statusText)
                if(res.data.code!=0) return this.$message.error('Response Error: '+res.data.msg)
                let data = res.data.data
                this.form.videoUrl = data.videoUrl
                this.$message.success("上传成功")
            })
        },

        //上传
        startUpload(){
            if(!this.file) return this.$notify.error('选择文件后，再上传')
            if(this.aliUpload) {
                this.aliUpload.startUpload()
            }else{
                console.log('选择文件再上传')
            }
            
        },

        //获取tree
        async getTree(){
            tree().then(res=>{
                this.data = res.teachers
            })
        },
        handleCascader(){
            this.form.teacherId = ''
            this.get_teacher()
        },
        //教师
        get_teacher(){
            this.teacher.query.subjectId = this.form.subjectId
            teacher_list(this.teacher.query).then(res=>{
                this.teacher.lists = res.data.list
            })
        },

        get_template(){
            template_list(this.template.query).then(res=>{
                this.template.lists = res.data.list
            })
        },
        //学科tree
        get_subject(){
            subjects().then(res=>{
                this.subject.tree = res.data
            })
        },

        onSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.req()
                } else {
                    return false;
                }
            });
        },

        remoteMethod(query){
            this.teacher.query.keyword=query
            this.get_teacher()
        },

        remoteMethodTemplate(query){
            this.template.query.name=query
            this.get_template()
        },

        //req
        req(){
            let t = this.getAllCheckedKeys()
            if(t!=null && t.length) this.form.reviewTeachers = t.join(',')
            let f = JSON.parse(JSON.stringify(this.form))
            f.type = (f.type == 3) ? 1 : f.type;
            create(f).then(res=>{
                if(res.code!=0) return this.$notify({title: '失败',message:res.msg,type: 'error'});
                this.$notify({title: '成功',message: "SUCCESS",type: 'success'});
                this.$router.go(-1)
            })
        },

        changeTeacher(val){
            this.form.subjectId =''
        },

         // 获取所有的复选框已经勾选的id
        getAllCheckedKeys() {
            let defaultCheckedKeys =[];
            // 获取ref
            const treeDataRef = this.$refs.tree;
            let treeDataList = [...treeDataRef.getCheckedNodes(), ...treeDataRef.getHalfCheckedNodes()];
            treeDataList.forEach(item=>{
                if(item.id && !defaultCheckedKeys.includes(item.id)) {
                    defaultCheckedKeys.push(item.id)
                }
            })
            return defaultCheckedKeys;
        },

        changeType(val){
            if(val==2) {
                //直播
                this.form.videoUrl = ''
            }else{
                //视频
                this.form.liveStreamUrl = ''
            }
        },

        changeTemplate(val){
            this.template.query.name = ''
            this.get_template()
        },
        //上传视频
        uploadSuccess(res) {
            if(res.code!=0) return this.$notify.error({title:'错误',message: res.data.msg});
            this.$notify({title: '成功',message: "SUCCESS",type: 'success'});
            this.form.videoUrl = res.data.videoUrl
        },

        //重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        //关闭抽屉
        closeDrawer(){
            this.$router.go(-1)
        }
    }

}
</script>


<style lang="less" scoped>
.drawer_form {
    width: 96%;
    margin: auto;
    .drawer_form_title {
        color: #17233d;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        line-height: 48px;
        margin-bottom: 20px;
    }

    .drawer_line {
        margin-bottom: 30px;
        .drawer_line_text {
            width: 120px;
            margin-right: 20px;
            text-align: right;
            color: #515a6e;
        }    
    }

}
.form-items {
    background-color:#f5f6fb;padding:15px 10px;height:500px;
}

/deep/ .form-items .el-form-item ,/deep/ .form-items .el-form-item .el-form-item__content{
    width: 100%;
}
/deep/ .el-drawer {
    width: 900px!important;
}
@media (max-width:990px) {
    /deep/ .el-drawer {
        width: 100%!important;
        font-size: 14px;
    }
}
</style>

